import React, { useEffect, useState, useCallback } from 'react';
import './ConfrontationDetail.css';
import useWebSocket from '../../services/websocket';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getConfrontation, setIsAddingChoice, abandonConfrontation } from '../../redux/actions/confrontationActions';
import { fetchUser, decrementKeys, decrementUsage } from '../../redux/actions/authActions';
import { addChoice, updateChoice } from '../../redux/actions/choiceActions';
import { ExpandLess, ChevronRight, Edit, EditOutlined } from '@mui/icons-material';
import defaultFigureImage from '../../images/default_figure.jpg';
import Loading from '../common/Loading';
import Continue from './Continue';
import Checkout from './Checkout';
import UnderstandingJournal from './UnderstandingJournal';

const ConfrontationDetail = () => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const confrontation = useSelector(state => state.confrontations.confrontations.find(confrontation => confrontation.uid === uid));
  const isAddingChoice = useSelector(state => state.confrontations.isAddingChoice); 
  const [loading, setLoading] = useState(true); 
  const user = useSelector(state => state.auth.user);
  const [showContinuePopup, setShowContinuePopup] = useState(false);
  const [continuePopupOptions, setContinuePopupOptions] = useState([]);
  const [continuePopupChoice, setContinuePopupChoice] = useState(null);
  const [showCheckout, setShowCheckout] = useState(false);
  const [orderType, setOrderType] = useState(null);
  const [packageUid, setPackageUid] = useState(null);
  const [price, setPrice] = useState(null);

  useEffect(() => {
    dispatch(getConfrontation(uid)).then(() => setLoading(false)); 
  }, [dispatch, uid]);

  useEffect(() => {
    setMessages([]);
    setIsChoiceConfirmed(false);
    setCurrentDecision('');
  }, [confrontation]);

  const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const wsHost = process.env.NODE_ENV === 'development' ? 'localhost:8000' : 'wenjingzhizhi.com';
  const wsUrl = `${wsProtocol}://${wsHost}/ws/confrontation/${uid}`;
  
  const { message } = useWebSocket(wsUrl);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (message) {
      setMessages(prevMessages => [...prevMessages, message]);
      if (message.includes('generation completed')) {
        dispatch(setIsAddingChoice(false)); 
        dispatch(getConfrontation(uid));
      } else if (message.includes('confrontation completed')) {
        dispatch(fetchUser());
      }
    }
  }, [message, dispatch, uid]);

  const combinedMessages = messages.join('');
  const triggerString = '"narrative": "';
  const triggerIndex = combinedMessages.indexOf(triggerString);
  const endString = '",';
  const shouldRenderMessages = triggerIndex !== -1;
  const messageToRender = shouldRenderMessages ? combinedMessages.slice(triggerIndex + triggerString.length, combinedMessages.indexOf(endString, triggerIndex)) : '';

  const [percentage, setPercentage] = useState(0);
  const [isChoiceConfirmed, setIsChoiceConfirmed] = useState(false);
  const [currentDecision, setCurrentDecision] = useState('');

  const [expandedChoice, setExpandedChoice] = useState(null);
  const toggleChoiceVisibility = (uid) => {
    setExpandedChoice(expandedChoice === uid ? null : uid);
  };
  const [isContainerVisible, setIsContainerVisible] = useState(false);
  const toggleContainerVisibility = () => {
    setIsContainerVisible(!isContainerVisible);
  };

  const handleAddChoice = useCallback(() => {
    dispatch(setIsAddingChoice(true)); 
    const newChoice = {
      confrontation: confrontation.id,
      narrative: "加载中...",
      options: {}
    };
    dispatch(addChoice(newChoice, confrontation.uid));
  }, [dispatch, confrontation]);

  const handleUpdateChoice = (uid, decision) => {
    const updatedChoice = {
      uid: uid, 
      decision: decision,
    };
    dispatch(updateChoice(updatedChoice, confrontation.uid));
  };

  const shouldShowContinuePopup = () => {
    if (confrontation && confrontation.choices && confrontation.choices.length === 3) {
      const options = [];
      if (user.has_active_package) {
        if (user.remaining_usage === 0) {
          if (user.remaining_keys > 0) {
            options.push('use_key', 'buy_package', 'abandon');
          } else {
            options.push('buy_key', 'buy_package', 'abandon');
          }
        } else {
          options.push('continue', 'abandon');
        }
      } else {
        if (user.remaining_keys > 0) {
          options.push('use_key', 'abandon');
        } else {
          options.push('buy_key', 'buy_package', 'abandon');
        }
      }
      setContinuePopupOptions(options);
      return true;
    }
    return false;
  }

  const handleContinuePopupChoice = (choice, price, packageUid) => {
    if (choice === 'abandon') {
      dispatch(abandonConfrontation(confrontation.uid));
      window.location.href = '/';
    } else if (choice === 'buy_key' || choice === 'buy_package') {
      setOrderType(choice);
      setPrice(price);
      setPackageUid(packageUid);
      setShowCheckout(true, packageUid);
    } else {
      if (choice === 'use_key') {
        dispatch(decrementKeys());
      } else if (choice === 'continue') {
        dispatch(decrementUsage());
      }
      setShowContinuePopup(false);
      setIsChoiceConfirmed(true);
      setCurrentDecision(continuePopupChoice.decision);
      handleUpdateChoice(continuePopupChoice.uid, continuePopupChoice.decision);
    }
  };

  useEffect(() => {
    if (confrontation && confrontation.choices) {
      if (confrontation.choices.length === 0 && !isAddingChoice) {
        handleAddChoice();
      }
      else {
        const decisionCount = confrontation.choices.filter(choice => choice.decision).length;
        let newPercentage = (decisionCount / (confrontation.story_structure.arcs.length - 1)) * 100;
        if (decisionCount === 0) {
          newPercentage = 1;
        }
        setPercentage(newPercentage);
      }
    }
  }, [confrontation, handleAddChoice, isAddingChoice]);

  const [showArchetype, setShowArchetype] = useState(false);
  const toggleArchetype = () => {
    setShowArchetype(!showArchetype);
  };

  useEffect(() => {
    if (confrontation) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [isChoiceConfirmed, confrontation]);

  useEffect(() => {
    if (user && confrontation) {
      shouldShowContinuePopup();
    }
  }, [user, confrontation]);

  if (loading) {
    return <Loading />; 
  }

  const ChoiceSection = ({ choice, expandedChoice, toggleChoiceVisibility, index, decision }) => {
    const isExpanded = expandedChoice === choice.uid;
    const arrowIcon = isExpanded ? <ExpandLess /> : <ChevronRight />;
  
    return (
      <div className="choice-section previous-choice">
        {isExpanded && (
          <div className="choice-content">
            {choice.previous_karmic_record && (
              <div className="choice-previous-karmic-record">🍎 往昔复现: {choice.previous_karmic_record.record}</div>
            )}
            <div className="choice-narrative">{choice.narrative}</div>
          </div>
        )}
        <div className='choice-decision' onClick={() => toggleChoiceVisibility(choice.uid)} style={{ cursor: 'pointer' }}>
          {arrowIcon} {'选择: ' + (decision !== undefined ? decision : choice.decision)} {choice.previous_karmic_record && '(往昔复现)'}
        </div>
      </div>
    );
  };
  
  const CurrentChoice = ({ choice, handleUpdateChoice }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  
    const handleOptionSelect = (optionContent) => {
      setSelectedOption(optionContent);
      setIsConfirmDisabled(false);
    };
  
    const handleConfirmChoice = () => {
      if (shouldShowContinuePopup()) {
        setContinuePopupChoice({ uid: choice.uid, decision: selectedOption });
        setShowContinuePopup(true);
        return;
      }
      if (selectedOption) {
        setIsConfirmDisabled(true);
        setIsChoiceConfirmed(true);
        setCurrentDecision(selectedOption);
        handleUpdateChoice(choice.uid, selectedOption);
      }
    };
  
    return (
      <div className="current-choice">
        <div className="current-choice-narrative">{choice.narrative}</div>
        <div className="options-container">
          {Object.values(choice.options).map((option, index) => (
            <button
              key={index}
              onClick={() => handleOptionSelect(option.content)}
              className={selectedOption === option.content ? 'selected' : ''}
            >
              {option.content}
            </button>
          ))}
        </div>
        <button className="current-choice-confirm-button" onClick={handleConfirmChoice} disabled={!selectedOption || isConfirmDisabled}>
          确认选择
        </button>
      </div>
    );
  };

  const figureContainerClass = confrontation.ending ? 'figure-container ended' : 'figure-container ongoing';

  return (
    <div className="confrontation-screen">
      {showContinuePopup && <Continue onClose={() => setShowContinuePopup(false)} onChoice={handleContinuePopupChoice} popupOptions={continuePopupOptions} user={user} />}
      {showCheckout && <Checkout user={user} orderType={orderType} price={price} packageUid={packageUid} confrontationUid={confrontation.uid} onClose={() => setShowCheckout(false)} />}
      {isContainerVisible && <UnderstandingJournal confrontationId={confrontation.id} closePopup={toggleContainerVisibility} />}
      <button className="toggle-journal-button" onClick={toggleContainerVisibility}>
        {isContainerVisible ? <Edit /> : <EditOutlined />} 笔记
      </button>
      <div className="confrontation-content">
        <div className="choices-container">
          {confrontation.choices && confrontation.choices.length > 0 && (
            confrontation.choices.filter(choice => choice.decision).length === (confrontation.story_structure.arcs.length-1)
              ? confrontation.choices.map((choice, index) => (
                  <ChoiceSection
                    key={choice.uid}
                    choice={choice}
                    expandedChoice={expandedChoice}
                    toggleChoiceVisibility={toggleChoiceVisibility}
                    index={index}
                  />
                ))
              : (
                <>
                  {confrontation.choices.slice(0, -1).map((choice, index) => (
                    <ChoiceSection
                      key={choice.uid}
                      choice={choice}
                      expandedChoice={expandedChoice}
                      toggleChoiceVisibility={toggleChoiceVisibility}
                      index={index}
                    />
                  ))}
                  {isChoiceConfirmed ? (
                    <ChoiceSection
                      choice={confrontation.choices[confrontation.choices.length - 1]}
                      expandedChoice={expandedChoice}
                      toggleChoiceVisibility={toggleChoiceVisibility}
                      index={confrontation.choices.length - 1}
                      decision={currentDecision}
                    />
                  ) :
                    <CurrentChoice
                      choice={confrontation.choices[confrontation.choices.length - 1]}
                      handleUpdateChoice={handleUpdateChoice}
                    />
                  }
                </>
              )
          )}
          {shouldRenderMessages && (
            <div className="streaming-container">
              <div>{messageToRender}</div>
            </div>
          )}
          {confrontation.ending && confrontation.ending.trim() !== '' && (
            <div className="ending-section">
              <div className="ending-label">尾声</div>
              <div className="ending-content">{confrontation.ending}</div>
            </div>
          )}
        </div>
        <div className={figureContainerClass}>
          <div className="figure-card">
            {showArchetype ? (
              <img src={confrontation.figure.archetype.image || defaultFigureImage} alt={confrontation.figure.archetype.name} className="figure-image" />
            ) : (
              <img src={confrontation.figure.image || defaultFigureImage} alt={confrontation.figure.name} className="figure-image" />
            )}
            {confrontation.ending && (
              <>
                <button className="toggle-button" onClick={toggleArchetype}>
                  {showArchetype ? '查看人物画像' : '查看人物原型'}
                </button>
                <div className="figure-details">
                  {showArchetype ? (
                    <>
                      <div className="details-name">人物原型: {confrontation.figure.archetype.name}</div>
                      <div className="details-description">{confrontation.figure.archetype.description}</div>
                    </>
                  ) : (
                    <>
                      <div className="details-name">{confrontation.figure.name}</div>
                      <div className="details-description">{confrontation.consciousness_evolution}</div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="confrontation-progress-bar">
        <div className="confrontation-progress" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );
};

export default ConfrontationDetail;