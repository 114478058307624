import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const Confirm = ({ onClose, onConfirm, message }) => (
  <div className="popup-background">
    <div className="popup">
      <div className="popup-header">
        <CloseIcon className="close-icon" onClick={onClose} />
      </div>
      <div className="popup-title">确认操作</div>
      <div className="popup-content">
        <p>{message}</p>
        <button onClick={onConfirm}>确认</button>
        <button onClick={onClose}>取消</button>
      </div>
    </div>
  </div>
);

export default Confirm;