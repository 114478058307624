import { GET_CALIBRATION_INVENTORY, ADD_CALIBRATION_RECORD } from "../types";
import { fetchData } from '../../services/api';

export const getCalibrationInventory = (name) => async (dispatch) => {
    try {
        const data = await fetchData(`ci/${name}/`);
        dispatch({ type: GET_CALIBRATION_INVENTORY, payload: data });
    } catch (error) {
        console.error('Error getting calibration inventory:', error);
    }
}

export const addCalibrationRecord = (record) => async (dispatch) => {
    try {
        const response = await fetchData('cr/', {
            method: 'POST',
            body: JSON.stringify(record),
            headers: { 'Content-Type': 'application/json' },
        });
        dispatch({ type: ADD_CALIBRATION_RECORD, payload: response });
    } catch (error) {
        console.error('Error adding calibration record:', error);
    }
}