import { GET_RECOMMENDED_FIGURES, GET_CONFRONTED_FIGURES, GET_FIGURE } from '../types';
import { fetchData } from '../../services/api';

export const getRecommendedFigures = () => async (dispatch) => {
    try {
        const data = await fetchData('recommended-figures/');
        dispatch({ type: GET_RECOMMENDED_FIGURES, payload: data });
    } catch (error) {
        console.error('Error getting figures:', error);
    }
}

export const dismissFigure = (uid) => async (dispatch) => {
    try {
        await fetchData(`dismiss-figure/${uid}/`, { method: 'POST' });
    } catch (error) {
        console.error('Error dismissing figure:', error);
    }
}

export const toggleFavoriteFigure = (uid) => async (dispatch) => {
    try {
        await fetchData(`toggle-favorite-figure/${uid}/`, { method: 'POST' });
    } catch (error) {
        console.error('Error toggling favorite:', error);
    }
}

export const getConfrontedFigures = () => async (dispatch) => {
    try {
        const data = await fetchData('confronted-figures/');
        dispatch({ type: GET_CONFRONTED_FIGURES, payload: data });
    } catch (error) {
        console.error('Error getting figures:', error);
    }
}

export const getFigure = (uid) => async (dispatch) => {
    try {
        const data = await fetchData(`f/${uid}/`);
        dispatch({ type: GET_FIGURE, payload: data });
        return data;
    } catch (error) {
        console.error('Error getting figure:', error);
    }
};
    