import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCalibrationInventory, addCalibrationRecord } from '../../redux/actions/calibrationActions';
import './CalibrationInventoryForm.css';
import logo from '../../logo.svg'; 
import { Divider } from '@mui/material';
import Loading from '../common/Loading';

const CalibrationInventoryForm = ({ user }) => {
    const dispatch = useDispatch();
    const inventory = useSelector((state) => state.calibration.inventory);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [previousQuestionIndices, setPreviousQuestionIndices] = useState([]);
    const [questionsAnswered, setQuestionsAnswered] = useState(0);

    useEffect(() => {
        dispatch(getCalibrationInventory('default'));
    }, [dispatch]);

    if (!user || !inventory) {
        return null;
    }

    const currentQuestion = inventory?.questions?.[currentQuestionIndex];
    const totalQuestions = 3;
    const progressPercentage = questionsAnswered / totalQuestions * 100;

    const handleAnswerSelect = (answerOption) => {
        setUserAnswers((prevAnswers) => {
            const updatedAnswers = [
                ...prevAnswers,
                {
                    order: currentQuestion.order,
                    question: currentQuestion.content, 
                    answer: answerOption.content,
                },
            ];

            if (answerOption.next_question) {
                const nextQuestionIndex = inventory.questions.findIndex(
                    (q) => q.uid === answerOption.next_question.uid
                );
                setPreviousQuestionIndices((prevIndices) => [...prevIndices, currentQuestionIndex]);
                setCurrentQuestionIndex(nextQuestionIndex);
                setQuestionsAnswered(prevCount => prevCount + 1);
            } else {
                setCurrentQuestionIndex(null);
                handleFinish(updatedAnswers, answerOption.consciousness_level);
            }

            return updatedAnswers;
        });
    };

    const handlePreviousQuestion = () => {
        setPreviousQuestionIndices((prevIndices) => {
            const lastIndex = prevIndices.pop();
            setCurrentQuestionIndex(lastIndex);
            setQuestionsAnswered(prevCount => prevCount - 1);
            return [...prevIndices];
        });
    };

    const handleFinish = (updatedAnswers, consciousness_level) => {
        const record = {
            individual_consciousness: user.individual_consciousness_id,
            inventory: inventory.id,
            answers: updatedAnswers,
            consciousness_level: consciousness_level,
        };
        dispatch(addCalibrationRecord(record)).then(() => {
            window.location.reload();
        });
    };
    
    if (!currentQuestion) {
        return (
            <div className="calibration-form loading-animation">
                <Loading />
            </div>
        );
    }
    return user.consciousness_level === 1 ? (
        <div className="calibration-form">
            <img src={logo} alt="Logo" className="logo" />
            <h2>欢迎进入「长乐未央」</h2>
            <h4>{currentQuestion.content}</h4>
            {currentQuestion.answer_options.map((option, index) => (
                <button className='answer-option' key={index} onClick={() => handleAnswerSelect(option)}>
                    {option.content}
                </button>
            ))}
            <Divider />
            <h5>请选择最符合你当下心境的选项</h5>
            <div className="progress-bar">
                <div className="progress-bar-inner" style={{ width: `${progressPercentage}%` }}></div>
            </div>
            {previousQuestionIndices.length > 0 && (
                <button className='back-button' onClick={handlePreviousQuestion}>上一题</button>
            )}
        </div>
    ) : null;
};

export default CalibrationInventoryForm;