import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFigure } from '../../redux/actions/figureActions';
import { addConfrontation } from '../../redux/actions/confrontationActions';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './FigureDetail.css'; 
import Loading from '../common/Loading';

const FigureDetail = () => {
    const { uid } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const figure = useSelector(state => state.figures.figures.find(figure => figure.uid === uid));

    useEffect(() => {
        dispatch(getFigure(uid));
    }, [dispatch, uid]);

    const user = useSelector(state => state.auth.user);

    const handleAddConfrontation = () => {
        const newConfrontation = {
            individual_consciousness: user.individual_consciousness_id,
            figure: figure.id
        };
        dispatch(addConfrontation(newConfrontation)).then((confrontation) => {
            navigate(`/c/${confrontation.uid}`);
        });
    };

    if (!figure) {
        return <Loading />;
    }

    const allConfrontationsHaveTitles = figure.confrontations.every(confrontation => confrontation.title);

    return (
        <div className="figure-detail-container">
            <div className="figure-detail-header">
                <img src={figure.image} alt={figure.name} className="figure-detail-image" />
                <div className="figure-detail-info">
                    <h1 className="figure-detail-name">{figure.name}</h1>
                    <p className="figure-detail-description">{figure.description}</p>
                    {allConfrontationsHaveTitles ? (
                        <button className="figure-detail-confrontation-btn" onClick={() => handleAddConfrontation(figure.uid)}>
                            开启
                        </button>
                    ) : null}
                </div>
            </div>

            <div className="figure-detail-confrontations">
                {figure.confrontations.map(confrontation => (
                    <div key={confrontation.uid} className="figure-detail-confrontation-item">
                        {confrontation.title ? (
                            <Link to={`/c/${confrontation.uid}`} className="figure-detail-confrontation-link">
                                {confrontation.title}
                            </Link>
                        ) : (
                            <Link to={`/c/${confrontation.uid}`} className="figure-detail-confrontation-link continue">
                                继续
                            </Link>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FigureDetail;
