import { GET_REALMS, GET_REALM } from "../types";
import { fetchData } from '../../services/api';

export const getRealms = () => async (dispatch) => {
    try {
        const data = await fetchData('realms/');
        dispatch({ type: GET_REALMS, payload: data });
    } catch (error) {
        console.error('Error getting realms:', error);
    }
}

export const getRealm = (uid) => async (dispatch) => {
    try {
        const data = await fetchData(`r/${uid}/`);
        dispatch({ type: GET_REALM, payload: data });
        return data;
    } catch (error) {
        console.error('Error getting realm:', error);
    }
}