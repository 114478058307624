import { useEffect, useState } from 'react';

const useWebSocket = (url) => {
    const [ws, setWs] = useState(null);
    const [message, setMessage] = useState(null);
    
    useEffect(() => {
        const socket = new WebSocket(url);
        socket.onopen = () => {
            console.log('WebSocket connection established');
        };
        socket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            setMessage(parsedData.response);
        };
        socket.onclose = () => {
            console.log('WebSocket connection closed');
        };
        setWs(socket);
    
        return () => {
            socket.close();
        };
    }, [url]);
    
    return { ws, message };
}

export default useWebSocket;