import React from 'react';
import ArchetypeDetail from '../components/Explore/ArchetypeDetail';

const Archetype = () => {
    return (
        <div>
            <main>
            <ArchetypeDetail />
            </main>
        </div>
    );
}

export default Archetype;