/**
 * Formats a date to a localized string in Chinese.
 * @param {string|Date} timestamp - The date to format.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};