import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="boat">
          <div className="hull"></div>
          <div className="sail"></div>
      </div>
      <div className="waves">
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
      </div>
  </div>
  );
};

export default Loading;
