import { GET_CONSCIOUSNESS, UPDATE_CONSCIOUSNESS, GET_OTHER_PREOCCUPATIONS, TOGGLE_PREOCCUPATION } from '../types';

const initialState = {};

export const consciousnessReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONSCIOUSNESS:
      return { ...state, consciousness: action.payload };
    case UPDATE_CONSCIOUSNESS:
      return { ...state, consciousness: action.payload };
    case GET_OTHER_PREOCCUPATIONS:
      return { ...state, otherPreoccupations: action.payload };
    case TOGGLE_PREOCCUPATION:
      const preoccupation = state.consciousness.preoccupations.find(preoccupation => preoccupation.uid === action.payload);
      if (preoccupation) {
        return {
          ...state,
          consciousness: {
            ...state.consciousness,
            preoccupations: state.consciousness.preoccupations.filter(preoccupation => preoccupation.uid !== action.payload)
          },
          otherPreoccupations: state.otherPreoccupations ? [...state.otherPreoccupations, preoccupation] : state.otherPreoccupations
        };
      } else {
        const preoccupation = state.otherPreoccupations.find(preoccupation => preoccupation.uid === action.payload);
        return {
          ...state,
          consciousness: {
            ...state.consciousness,
            preoccupations: [...state.consciousness.preoccupations, preoccupation]
          },
          otherPreoccupations: state.otherPreoccupations.filter(preoccupation => preoccupation.uid !== action.payload)
        };
      }
    default:
      return state;
  }
};
