import React from 'react';
import { useSelector } from 'react-redux';
import IndividualConsciousness from '../components/Dashboard/IndividualConsciousness';
import FigureList from '../components/Dashboard/FigureList';
import CalibrationInventoryForm from '../components/Dashboard/CalibrationInventoryForm';

const Home = () => {
  const user = useSelector(state => state.auth.user);
  const inventory = useSelector(state => state.calibration.inventory);

  const isCalibrationFormRendered = user && user.consciousness_level === 1 && inventory;

  return (
    <div>
      <main className={isCalibrationFormRendered ? 'main-no-padding' : ''}>
        {isCalibrationFormRendered ? (
          <CalibrationInventoryForm user={user} />
        ) : (
          <>
            <IndividualConsciousness user={user} />
            <FigureList />
          </>
        )}
      </main>
    </div>
  );
};

export default Home;