import { 
  GET_CONFRONTATIONS, GET_ONGOING_CONFRONTATIONS, ADD_CONFRONTATION, 
  GET_CONFRONTATION, ADD_UNDERSTANDING, UPDATE_UNDERSTANDING, 
  SET_IS_ADDING_CHOICE, ABANDON_CONFRONTATION, FORGET_CONFRONTATION,
} from '../types';
import { fetchData } from '../../services/api';

export const getConfrontations = () => async (dispatch) => {
  try {
    const data = await fetchData('confrontations/');
    dispatch({ type: GET_CONFRONTATIONS, payload: data });
  } catch (error) {
    console.error('Error getting confrontations:', error);
  }
};

export const getOngoingConfrontations = () => async (dispatch) => {
  try {
    const data = await fetchData('ongoing-confrontations/');
    dispatch({ type: GET_ONGOING_CONFRONTATIONS, payload: data });
  } catch (error) {
    console.error('Error getting ongoing confrontations:', error);
  }
}

export const addConfrontation = (confrontation) => async (dispatch) => {
  try {
    const response = await fetchData('confrontations/', {
      method: 'POST',
      body: JSON.stringify(confrontation),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch({ type: ADD_CONFRONTATION, payload: response });
    return response;
  } catch (error) {
    console.error('Error adding confrontation:', error);
  }
};

export const getConfrontation = (uid) => async (dispatch) => { 
  try {
    const data = await fetchData(`c/${uid}/`);
    dispatch({ type: GET_CONFRONTATION, payload: data });
  } catch (error) {
    console.error('Error getting confrontation:', error);
  }
}

export const addUnderstanding = (understanding) => async (dispatch) => {
  try {
    const response = await fetchData('understandings/', {
      method: 'POST',
      body: JSON.stringify(understanding),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch({ type: ADD_UNDERSTANDING, payload: response });
  } catch (error) {
    console.error('Error adding understanding:', error);
  }
}

export const updateUnderstanding = (understanding) => async (dispatch) => {
  try {
    const response = await fetchData(`understandings/${understanding.uid}/`, {
      method: 'PUT',
      body: JSON.stringify(understanding),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch({ type: UPDATE_UNDERSTANDING, payload: response });
  } catch (error) {
    console.error('Error updating understanding:', error);
  }
}

export const setIsAddingChoice = (isAdding) => ({
  type: SET_IS_ADDING_CHOICE,
  payload: isAdding,
});

export const abandonConfrontation = (uid) => async (dispatch) => {
  try {
    const response = await fetchData(`c/${uid}/abandon/`, { method: 'POST' });
    dispatch({ type: ABANDON_CONFRONTATION, payload: response });
  } catch (error) {
    console.error('Error abandoning confrontation:', error);
  }
}

export const forgetConfrontation = (uid) => async (dispatch) => {
  try {
    const response = await fetchData(`c/${uid}/forget/`, { method: 'POST' });
    dispatch({ type: FORGET_CONFRONTATION, payload: response });
  } catch (error) {
    console.error('Error forgetting confrontation:', error);
  }
}