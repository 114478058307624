import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  DECREMENT_KEYS,
  DECREMENT_USAGE,
} from '../actions/authActions';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: !!localStorage.getItem('token'),
  isLoading: false,
  error: null,
  user: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case FETCH_USER_REQUEST:
      return { ...state, isLoading: true, error: null };
    case LOGIN_SUCCESS:
      return { ...state, isLoading: false, isAuthenticated: true, token: action.payload, error: null };
    case LOGIN_FAILURE:
    case FETCH_USER_FAILURE:
      return { ...state, isLoading: false, isAuthenticated: false, error: action.payload };
    case FETCH_USER_SUCCESS:
      return { ...state, isLoading: false, user: action.payload, error: null };
    case LOGOUT:
      return { ...state, isAuthenticated: false, token: null, user: null };
    case DECREMENT_KEYS:
      return { ...state, user: { ...state.user, remaining_keys: state.user.remaining_keys - 1 } };
    case DECREMENT_USAGE:
      return { ...state, user: { ...state.user, remaining_usage: state.user.remaining_usage - 1 } };
    default:
      return state;
  }
};