import { GET_REALMS, GET_REALM } from "../types";

const initialState = {
    realms: [],
};

export const realmReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REALMS:
            return { ...state, realms: action.payload };
        case GET_REALM:
            return { 
                ...state, 
                realms: state.realms.some(realm => realm.uid === action.payload.uid)
                    ? state.realms.map(realm => realm.uid === action.payload.uid ? action.payload : realm)
                    : [...state.realms, action.payload]
            };
        default:
            return state;
    }
}