import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRealms } from '../../redux/actions/realmActions';
import { Link } from 'react-router-dom';
import './RealmList.css';

const RealmList = () => {
  const dispatch = useDispatch();
  const realms = useSelector(state => state.realms.realms);

  useEffect(() => {
    if (!realms || realms.length <= 1) {
      dispatch(getRealms());
    }
  }, [dispatch, realms]);

  return (
    <div className="realm-list">
      <h3 className="realm-list-heading">意识境界</h3>
      <div className="realm-items">
        {realms.map(realm => (
          <Link to={`/r/${realm.uid}`}>
            <div key={realm.uid} className="realm-item">
              {realm.name}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RealmList;