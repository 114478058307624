import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GrassIcon from '@mui/icons-material/Grass';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import ParkTwoToneIcon from '@mui/icons-material/ParkTwoTone';
import SpaIcon from '@mui/icons-material/Spa';
import { formatDate } from '../../utils/helpers';
import './KarmicRecordList.css';

const KarmicRecordList = () => {
  const consciousness = useSelector(state => state.consciousness.consciousness);
  const karmicRecords = consciousness ? consciousness.karmic_records : null;

  return (
    <div className='karmic-record-list'>
      {karmicRecords && karmicRecords.length > 0 ? (
        karmicRecords.map(karmicRecord => (
          <Accordion key={karmicRecord.uid}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Box className="karmic-record-summary" display="flex" alignItems="center">
                {karmicRecord.quality === 'positive' ? (
                  <GrassIcon style={{ color: 'green' }} />
                ) : (
                  <BugReportOutlinedIcon style={{ color: 'red' }} />
                )}
                <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>
                  {karmicRecord.record}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {karmicRecord.influenced_choices.length === 0 ? (
                <Box display="flex" alignItems="center">
                  <SpaIcon style={{ color: '#4caf50', marginRight: '10px' }} />
                  <Typography variant="body2">尚未复现。</Typography>
                </Box>
              ) : (
                <Box>
                  {karmicRecord.influenced_choices.map(influencedChoice => (
                    <Box key={influencedChoice.uid} display="flex" alignItems="center" mb={1}>
                      <ParkTwoToneIcon style={{ color: '#4caf50', marginRight: '10px' }} />
                      <Typography variant="body2">
                        {influencedChoice.narrative} {' '}
                        <Link to={`/c/${influencedChoice.confrontation.uid}`} style={{ color: '#007BFF', fontWeight: 'bold' }}>回顾</Link>
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              <Box className="karmic-record-meta" display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Typography variant="caption">
                  源自选择: {karmicRecord.resulting_from_choice.decision} {' '}
                  <Link to={`/c/${karmicRecord.resulting_from_choice.confrontation.uid}`} style={{ color: '#007BFF', fontWeight: 'bold' }}>回顾</Link>
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {formatDate(karmicRecord.timestamp)}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <p className="empty-message">暂未播下任何种子</p>
      )}
    </div>
  );
};

export default KarmicRecordList;