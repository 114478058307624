import React from 'react';
import ConfrontationDetail from '../components/ConfrontationArena/ConfrontationDetail';

const Confrontation = () => {
    return (
        <div>
            <main>
            <ConfrontationDetail />
            </main>
        </div>
    );
};

export default Confrontation;
