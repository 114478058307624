import React from 'react';
import './IndividualConsciousness.css';
import Loading from '../common/Loading';

const IndividualConsciousness = ({ user }) => {
  if (!user) {
    return <Loading />;
  }

  const percentage = (((user.consciousness_level - user.current_realm.min_consciousness_level) / (user.current_realm.max_consciousness_level - user.current_realm.min_consciousness_level)) * 100).toFixed(2);

  return (
    <div className='individual-consciousness'>
      <div className="evolution-bar">
        <div className="evolution" style={{ width: `${percentage}%` }}></div>
        <div className='current-realm'>当前意识境界: {user.current_realm.name}</div>
        <div className='percentage'>{percentage}%</div>
      </div>
    </div>
  );
};

export default IndividualConsciousness;