import React from 'react';
import FigureDetail from '../components/Dashboard/FigureDetail';

const Figure = () => {
    return (
        <div>
            <main>
            <FigureDetail />
            </main>
        </div>
    );
}

export default Figure;