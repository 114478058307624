import React, { useEffect } from 'react';
import './App.css';
import './styles/variables.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './redux/store';
import Navigation from './components/common/Navigation';
import Login from './components/common/Login';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Confrontation from './pages/Confrontation';
import Explore from './pages/Explore';
import Figure from './pages/Figure';
import Realm from './pages/Realm';
import Archetype from './pages/Archetype';
import { fetchUser, loginSuccess } from './redux/actions/authActions';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

const AppRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(loginSuccess(token)); 
      dispatch(fetchUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      <Route path="/explore" element={<ProtectedRoute><Explore /></ProtectedRoute>} />
      <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/c/:uid" element={<ProtectedRoute><Confrontation /></ProtectedRoute>} />
      <Route path="/r/:uid" element={<ProtectedRoute><Realm /></ProtectedRoute>} />
      <Route path="/a/:uid" element={<ProtectedRoute><Archetype /></ProtectedRoute>} />
      <Route path="/f/:uid" element={<ProtectedRoute><Figure /></ProtectedRoute>} />
    </Routes>
  );
};

const App = () => (
  <Provider store={store}>
    <Router>
      <Navigation />
      <AppRoutes />
    </Router>
  </Provider>
);

export default App;