import { 
  GET_CONFRONTATIONS, GET_ONGOING_CONFRONTATIONS, ADD_CONFRONTATION, 
  GET_CONFRONTATION, ADD_UNDERSTANDING, UPDATE_UNDERSTANDING, 
  SET_IS_ADDING_CHOICE, ABANDON_CONFRONTATION, FORGET_CONFRONTATION,
} from '../types';

const initialState = {
  confrontations: [],
  ongoingConfrontations: [],
  isAddingChoice: false, 
};

export const confrontationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFRONTATIONS:
      return { ...state, confrontations: action.payload };
    case GET_ONGOING_CONFRONTATIONS:
      return { ...state, ongoingConfrontations: action.payload };
    case ADD_CONFRONTATION:
      return { ...state, confrontations: [...state.confrontations, action.payload] };
    case GET_CONFRONTATION:
      return { ...state, confrontations: state.confrontations.some(confrontation => confrontation.uid === action.payload.uid)
        ? state.confrontations.map(confrontation => confrontation.uid === action.payload.uid ? action.payload : confrontation)
        : [...state.confrontations, action.payload]
      };
    case ADD_UNDERSTANDING:
      return {
        ...state,
        confrontations: state.confrontations.map(confrontation => 
          confrontation.id === action.payload.confrontation
            ? { ...confrontation, understandings: [...confrontation.understandings, action.payload] }
            : confrontation
        )
      };
    case UPDATE_UNDERSTANDING:
      return {
        ...state,
        confrontations: state.confrontations.map(confrontation => 
          confrontation.id === action.payload.confrontation
            ? { ...confrontation, understandings: confrontation.understandings.map(understanding => understanding.uid === action.payload.uid ? action.payload : understanding) }
            : confrontation
        )
      };
    case SET_IS_ADDING_CHOICE:
      return { ...state, isAddingChoice: action.payload };
    case ABANDON_CONFRONTATION:
      return { ...state, ongoingConfrontations: state.ongoingConfrontations.filter(confrontation => confrontation.uid !== action.payload) };
    case FORGET_CONFRONTATION:
      return { ...state, confrontations: state.confrontations.filter(confrontation => confrontation.uid !== action.payload) };
    default:
      return state;
  }
};