import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import './FigureList.css';
import { useSelector, useDispatch } from 'react-redux';
import { getRecommendedFigures, dismissFigure, toggleFavoriteFigure } from '../../redux/actions/figureActions';
import { getOngoingConfrontations, addConfrontation } from '../../redux/actions/confrontationActions';
import { Link, useNavigate } from 'react-router-dom';
import defaultFigureImage from '../../images/default_figure.jpg';
import { IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';

const FigureList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [view, setView] = useState('recommended'); 
    const [recommendedIndex, setRecommendedIndex] = useState(0);
    const [ongoingIndex, setOngoingIndex] = useState(0);
    const [figures, setFigures] = useState([]);

    const recommendedFigures = useSelector(state => state.figures.recommendedFigures);
    const ongoingConfrontations = useSelector(state => state.confrontations.ongoingConfrontations);
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        dispatch(getRecommendedFigures());
        dispatch(getOngoingConfrontations());
    }, [dispatch]);

    useEffect(() => {
        setFigures(recommendedFigures);
    }, [recommendedFigures]);

    useEffect(() => {
        if (ongoingConfrontations.length > 0) {
            setView('ongoing');
        } else {
            setView('recommended');
        }
    }, [ongoingConfrontations]);

    const handleAddConfrontation = (figureId) => {
        const newConfrontation = {
            individual_consciousness: user.individual_consciousness_id,
            figure: figureId
        };
        dispatch(addConfrontation(newConfrontation)).then((confrontation) => {
            navigate(`/c/${confrontation.uid}`);
        });
    };

    const handleDismissFigure = (figureUid) => {
        dispatch(dismissFigure(figureUid));
    };

    const handleToggleFavoriteFigure = (figureUid) => {
        dispatch(toggleFavoriteFigure(figureUid)).then(() => {
            const updatedFigures = figures.map(figure => 
                figure.uid === figureUid ? { ...figure, is_favorited: !figure.is_favorited } : figure
            );
            setFigures(updatedFigures);
        });
    };

    const handleSwipe = (direction) => {
        if (view === 'recommended') {
            if (direction === 'left') {
                handleDismissFigure(figures[recommendedIndex].uid);
                setRecommendedIndex((prevIndex) => (prevIndex + 1) % figures.length);
            } else if (direction === 'right') {
                setRecommendedIndex((prevIndex) => (prevIndex - 1 + figures.length) % figures.length);
            } 
        } else if (view === 'ongoing') {
            if (direction === 'left') {
                setOngoingIndex((prevIndex) => (prevIndex + 1) % ongoingConfrontations.length);
            } else if (direction === 'right') {
                setOngoingIndex((prevIndex) => (prevIndex - 1 + ongoingConfrontations.length) % ongoingConfrontations.length);
            }
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('left'),
        onSwipedRight: () => handleSwipe('right'),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const handleArrowClick = (direction) => {
        handleSwipe(direction);
    };

    const viewToggleClass = ongoingConfrontations.length > 0 ? 'view-toggle' : 'view-toggle no-ongoing';

    return (
        <div>
            <div className={viewToggleClass}>
                {ongoingConfrontations.length > 0 && (
                    <button onClick={() => setView('ongoing')} className={view === 'ongoing' ? 'active' : ''}>继续</button>
                )}
                <button onClick={() => setView('recommended')} className={view === 'recommended' ? 'active' : ''}>推荐</button>
            </div>
            <div {...handlers} style={{ touchAction: 'pan-y' }}>
                {view === 'recommended' ? (
                    <div className="figure-carousel">
                        {figures.length > 0 && (
                            <div className="figure-card-container">
                                {figures.length > 1 && (
                                    <ArrowBack className="arrow-icon left-arrow" onClick={() => handleArrowClick('right')} />
                                )}
                                <div key={figures[recommendedIndex].uid} className="figure-card" style={{ backgroundImage: `url(${figures[recommendedIndex].image || defaultFigureImage})`, ...figures[recommendedIndex].style }}>
                                    <IconButton className="favorite-button" onClick={() => handleToggleFavoriteFigure(figures[recommendedIndex].uid)} sx= {{ position: 'absolute' }}>
                                        {figures[recommendedIndex].is_favorited ? (
                                            <FavoriteIcon style={{ color: 'red' }} />
                                        ) : (
                                            <FavoriteBorderIcon />
                                        )}
                                    </IconButton>
                                    <div className="figure-card-content">
                                        <Link to={`/f/${figures[recommendedIndex].uid}`} className="figure-name">
                                            {figures[recommendedIndex].name}
                                        </Link>
                                        <div className="figure-card-buttons">
                                            <button onClick={() => handleAddConfrontation(figures[recommendedIndex].id)}>开启</button>
                                        </div>
                                    </div>
                                </div>
                                {figures.length > 1 && (
                                    <ArrowForward className="arrow-icon right-arrow" onClick={() => handleArrowClick('left')} />
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="figure-carousel">
                        {ongoingConfrontations.length > 0 && (
                            <div className="figure-card-container">
                                {ongoingConfrontations.length > 1 && (
                                    <ArrowBack className="arrow-icon left-arrow" onClick={() => handleArrowClick('right')} />
                                )}
                                <div key={ongoingConfrontations[ongoingIndex].uid} className="figure-card" style={{ backgroundImage: `url(${ongoingConfrontations[ongoingIndex].figure.image || defaultFigureImage})`, ...ongoingConfrontations[ongoingIndex].figure.style }}>
                                    <div className="figure-card-content">
                                        <Link to={`/f/${ongoingConfrontations[ongoingIndex].figure.uid}`} className="figure-name">
                                            {ongoingConfrontations[ongoingIndex].figure.name}
                                        </Link>
                                        <div className="figure-card-buttons">
                                            <Link to={`/c/${ongoingConfrontations[ongoingIndex].uid}`}><button>继续</button></Link>
                                        </div>
                                    </div>
                                </div>
                                {ongoingConfrontations.length > 1 && (
                                    <ArrowForward className="arrow-icon right-arrow" onClick={() => handleArrowClick('left')} />
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default FigureList;