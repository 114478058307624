import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getConfrontedFigures } from '../../redux/actions/figureActions';
import { Link } from 'react-router-dom';
import './ConfrontedFigureList.css';

const ConfrontedFigureList = () => {
    const dispatch = useDispatch();
    const figures = useSelector(state => state.figures.confrontedFigures);
    
    useEffect(() => {
        dispatch(getConfrontedFigures());
    }, [dispatch]);
    
    return (
        <div className="confronted-figure-list">
            {figures.length > 0 ? (
                figures.map(figure => (
                    <Link to={`/f/${figure.uid}`} className="figure-link">
                        <div className="figure-item" key={figure.uid}>{figure.name}</div>
                    </Link>
                ))
            ) : (
                <p className="empty-message">暂无熟人</p>
            )}
        </div>
    );
};

export default ConfrontedFigureList;
