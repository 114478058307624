import { GET_CHOICES, ADD_CHOICE, UPDATE_CHOICE } from '../types';

const initialState = {
  choices: [],
};

export const choiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHOICES:
      return { ...state, choices: action.payload };
    case ADD_CHOICE:
      return { ...state, choices: [...state.choices, action.payload] };
    case UPDATE_CHOICE:
      return { ...state, choices: state.choices.map(choice => choice.uid === action.payload.uid ? action.payload : choice) };
    default:
      return state;
  }
};
