import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/helpers';
import './UnderstandingList.css';

const UnderstandingList = () => {
    const consciousness = useSelector(state => state.consciousness.consciousness);
    const understandings = consciousness ? consciousness.understandings : null;

    return (
        <div className="understanding-list">
            {understandings && understandings.length > 0 ? (
                understandings.map(understanding => (
                    <div key={understanding.id} className="understanding-note">
                        <div className="understanding-wrapper">
                            <div className="understanding-content">
                                {understanding.content}
                            </div>
                            <div className="understanding-info">
                                <Link to={`/c/${understanding.confrontation.uid}`} className="review-link">{understanding.confrontation.figure.name}</Link>
                                <div>{formatDate(understanding.timestamp)}</div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p className="empty-message">暂无笔记</p>
            )}
        </div>
    );
};

export default UnderstandingList;