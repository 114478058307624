import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/selectors';
import { getConsciousness } from '../../redux/actions/consciousnessActions';
import { logout } from '../../redux/actions/authActions';
import { Tabs, Tab, Box } from '@mui/material';
import { Notes, History, Group, Favorite } from '@mui/icons-material';
import PreoccupationList from './PreoccupationList';
import UnderstandingList from './UnderstandingList';
import FavoritedFigureList from './FavoritedFigureList';
import ConfrontedFigureList from './ConfrontedFigureList';
import KarmicRecordList from './KarmicRecordList';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import './ProfileDetail.css';

const ProfileDetail = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(getConsciousness());
    }, [dispatch]);

    const getTabIndexFromHash = (hash) => {
        switch (hash) {
            case '#notes':
                return 0;
            case '#seeds':
                return 1;
            case '#acquainted':
                return 2;
            case '#favorited':
                return 3;
            default:
                return 0;
        }
    };

    const [activeTab, setActiveTab] = useState(getTabIndexFromHash(location.hash));

    useEffect(() => {
        setActiveTab(getTabIndexFromHash(location.hash));
    }, [location.hash]);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        switch (newValue) {
            case 0:
                navigate('#notes');
                break;
            case 1:
                navigate('#seeds');
                break;
            case 2:
                navigate('#acquainted');
                break;
            case 3:
                navigate('#favorited');
                break;
            default:
                break;
        }
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 0:
                return <UnderstandingList />;
            case 1:
                return <KarmicRecordList />;
            case 2:
                return <ConfrontedFigureList />;
            case 3:
                return <FavoritedFigureList />;
            default:
                return null;
        }
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className="profile-detail-container">
            <div className="profile-heading">
                <h2 className="user-name">{user ? (user.name || user.username) : 'Loading...'}</h2>
                <button className='logout-button' onClick={() => dispatch(logout())}>退出登录</button>
            </div>
            <PreoccupationList />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={handleChange} centered>
                    <Tab label={isMobile ? '' : '笔记'} icon={<Notes />} iconPosition="start" />
                    <Tab label={isMobile ? '' : '种子'} icon={<History />} iconPosition="start" />
                    <Tab label={isMobile ? '' : '熟人'} icon={<Group />} iconPosition="start" />
                    <Tab label={isMobile ? '' : '收藏'} icon={<Favorite />} iconPosition="start" />
                </Tabs>
            </Box>
            <Box>
                {renderTabContent()}
            </Box>
        </div>
    );
};

export default ProfileDetail;