import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BottomNavigation, BottomNavigationAction, Paper, Drawer, List, ListItem, ListItemIcon, ListItemText, useMediaQuery, Box, Typography, AppBar, Toolbar } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ExploreIcon from '@mui/icons-material/Explore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material/styles';
import logo from '../../logo.svg';
import './Navigation.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

const Navigation = () => {
  const user = useSelector(state => state.auth.user);
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const navigationItems = [
    { label: '首页', icon: <WbSunnyIcon />, to: '/', value: '/' },
    { label: '发现', icon: <ExploreIcon />, to: '/explore', value: '/explore' },
    { label: '我', icon: <AccountCircleIcon />, to: '/profile', value: '/profile' },
  ].filter(Boolean);

  const isConfrontationPage = /^\/c\/[a-zA-Z0-9]+$/.test(location.pathname);
  const isArchetypePage = /^\/a\/[a-zA-Z0-9]+$/.test(location.pathname);
  const isRealmPage = /^\/r\/[a-zA-Z0-9]+$/.test(location.pathname);
  const isFigurePage = /^\/f\/[a-zA-Z0-9]+$/.test(location.pathname);

  const uid = location.pathname.split('/').pop();
  
  const confrontation = useSelector(state => state.confrontations.confrontations.find(confrontation => confrontation.uid === uid));
  const archetype = useSelector(state => state.archetypes.archetypes.find(archetype => archetype.uid === uid));
  const realm = useSelector(state => state.realms.realms.find(realm => realm.uid === uid));
  const figure = useSelector(state => state.figures.figures.find(figure => figure.uid === uid));

  const pageTitle = confrontation ? confrontation.figure.name :
                    archetype ? archetype.name :
                    realm ? realm.name :
                    figure ? figure.name : '';

  if (user && user.consciousness_level === 1) {
    return null;
  }

  return (
    <>
      {(isConfrontationPage || isArchetypePage || isRealmPage || isFigurePage) && (
        <AppBar className="secondary-top-nav" position="fixed" sx={{ backgroundColor: 'var(--color-background)', color: theme.palette.text.primary, boxShadow: 'none', borderBottom: '1px solid var(--color-border)' }}>
          <Toolbar sx={{ minHeight: '48px !important', padding: '0 16px' }}>
            <IconButton edge="start" color="inherit" aria-label="back" onClick={() => window.history.back()}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className="logo-text" sx={{ fontSize: '1.1rem' }}>{pageTitle}</Typography>
          </Toolbar>
        </AppBar>
      )}
      {isDesktop ? (
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            '& .MuiDrawer-paper': {
              width: 240,
              boxSizing: 'border-box',
              backgroundColor: 'var(--color-background)',
              color: theme.palette.text.primary,
            },
          }}
        >
          <Box className="logo-container">
            <img src={logo} alt="Logo" className="logo-image" />
            <Typography variant="h6" className="logo-text">长乐未央</Typography>
          </Box>
          <List>
            {navigationItems.map((item) => (
              <ListItem
                key={item.value}
                component={Link}
                to={item.to}
                selected={value === item.value}
                onClick={() => setValue(item.value)}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.action.selected,
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                    '& .MuiListItemIcon-root': {
                      color: theme.palette.primary.main,
                    },
                    '& .MuiListItemText-primary': {
                      fontWeight: 'bold',
                      color: theme.palette.primary.main,
                    },
                  },
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                  transition: 'background-color 0.3s, color 0.3s',
                }}
              >
                <ListItemIcon sx={{ color: value === item.value ? theme.palette.primary.main : 'inherit' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} sx={{ color: value === item.value ? theme.palette.primary.main : 'inherit', fontWeight: value === item.value ? 'bold' : 'normal' }} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      ) : (
        <>
          {!(isConfrontationPage || isArchetypePage || isRealmPage || isFigurePage) && (
            <AppBar position="fixed" sx={{ backgroundColor: 'var(--color-background)', color: theme.palette.text.primary, boxShadow: 'none', borderBottom: '1px solid var(--color-border)' }}>
              <Toolbar sx={{ minHeight: '48px', padding: '0 16px' }}>
                <img src={logo} alt="Logo" className="logo-image" style={{ height: '32px' }} />
                <Typography variant="h6" className="logo-text" sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>长乐未央</Typography>
              </Toolbar>
            </AppBar>
          )}
          {!(isConfrontationPage || isArchetypePage || isRealmPage || isFigurePage) && (
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'var(--color-background)', zIndex: 1300 }} elevation={3}>
              <BottomNavigation
                className='mobile-bottom-navigation'
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                showLabels
                sx={{
                  backgroundColor: 'var(--color-background)',
                  '& .Mui-selected': {
                    color: theme.palette.primary.main,
                  },
                  '& .MuiBottomNavigationAction-root': {
                    transition: 'color 0.3s',
                    '&:hover': {
                      color: theme.palette.primary.light,
                    },
                  },
                }}
              >
                {navigationItems.map((item) => (
                  <BottomNavigationAction
                    key={item.value}
                    label={item.label}
                    icon={item.icon}
                    component={Link}
                    to={item.to}
                    value={item.value}
                  />
                ))}
              </BottomNavigation>
            </Paper>
          )}
        </>
      )}
    </>
  );
};

export default Navigation;