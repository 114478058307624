import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import { archetypeReducer } from './reducers/archetypeReducer';
import { authReducer } from './reducers/authReducer';
import { calibrationReducer } from './reducers/calibrationReducer';
import { choiceReducer } from './reducers/choiceReducer';
import { confrontationReducer } from './reducers/confrontationReducer';
import { consciousnessReducer } from './reducers/consciousnessReducer';
import { figureReducer } from './reducers/figureReducer';
import { orderReducer } from './reducers/orderReducer';
import { realmReducer } from './reducers/realmReducer';

const store = configureStore({
  reducer: {
    archetypes: archetypeReducer,
    auth: authReducer,
    calibration: calibrationReducer, 
    choice: choiceReducer,
    confrontations: confrontationReducer,
    consciousness: consciousnessReducer,
    figures: figureReducer,
    orders: orderReducer,
    realms: realmReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
