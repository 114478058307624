import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRealm } from '../../redux/actions/realmActions';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './RealmDetail.css'; 

const RealmDetail = () => {
    const { uid } = useParams();
    const dispatch = useDispatch();
    const realm = useSelector(state => state.realms.realms.find(realm => realm.uid === uid));

    useEffect(() => {
        dispatch(getRealm(uid));
    }, [dispatch, uid]);

    if (!realm) {
        return <div>加载中...</div>;
    }

    return (
        <div className="realm-detail-container">
            <div className="realm-header">
                <img src={realm.image} alt={realm.name} className="realm-image" />
                <div className="realm-info">
                    <h2 className="realm-name">{realm.name}</h2>
                    <p className="realm-description">{realm.description}</p>
                </div>
            </div>
            <h3>相关人物</h3>
            {realm.figures && realm.figures.length > 0 ? (
                <div className="realm-figures">
                    {realm.figures.map((figure, index) => (
                        <div key={index} className="figure-link">
                            <Link to={`/f/${figure.uid}`}>
                                {figure.name}
                            </Link>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="no-figures">暂无相关人物。</div>
            )}
        </div>
    );
};

export default RealmDetail;