import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addUnderstanding, updateUnderstanding } from '../../redux/actions/confrontationActions';
import CloseIcon from '@mui/icons-material/Close';

const UnderstandingJournal = ({ confrontationId, closePopup }) => {
  const [text, setText] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [understandingUid, setUnderstandingUid] = useState(null);
  const consciousnessId = useSelector(state => state.auth.user.individual_consciousness_id);
  const confrontation = useSelector(state => state.confrontations.confrontations.find(confrontation => confrontation.id === confrontationId));

  const dispatch = useDispatch();

  useEffect(() => {
    if (confrontation && confrontation.understandings && confrontation.understandings.length > 0) {
      const existingUnderstanding = confrontation.understandings[0];
      setText(existingUnderstanding.content);
      setIsEditMode(true);
      setUnderstandingUid(existingUnderstanding.uid);
    }
  }, [confrontation]);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = () => {
    const newUnderstanding = {
      individual_consciousness: consciousnessId,
      confrontation: confrontationId, 
      content: text
    };

    if (isEditMode) {
      dispatch(updateUnderstanding({ ...newUnderstanding, uid: understandingUid }));
    } else {
      dispatch(addUnderstanding(newUnderstanding));
    }

    setText('');
    setIsEditMode(false);
    setUnderstandingUid(null);
    closePopup();
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-header">
          <CloseIcon className="close-icon" onClick={closePopup} />
        </div>
        <div  className="popup-title">笔记</div>
        <div className="popup-content">
          <textarea
            value={text}
            onChange={handleChange}
            placeholder="在此记录顿悟、启发、灵感..."
            rows="7"
          />
          <button onClick={handleSubmit}>{isEditMode ? '更新' : '保存'}</button>
        </div>
      </div>
    </div>
  );
};

export default UnderstandingJournal;