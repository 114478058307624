import { GET_ARCHETYPES, GET_ARCHETYPE } from "../types";
import { fetchData } from "../../services/api";

export const getArchetypes = () => async (dispatch) => {
    try {
        const data = await fetchData("archetypes/");
        dispatch({ type: GET_ARCHETYPES, payload: data });
    } catch (error) {
        console.error("Error getting archetypes:", error);
    }
}

export const getArchetype = (uid) => async (dispatch) => {
    try {
        const data = await fetchData(`a/${uid}/`);
        dispatch({ type: GET_ARCHETYPE, payload: data });
    } catch (error) {
        console.error("Error getting archetype:", error);
    }
}
