import React, { useState } from 'react';
import './PreoccupationList.css';
import { useSelector, useDispatch } from 'react-redux';
import { getOtherPreoccupations, togglePreoccupation } from '../../redux/actions/consciousnessActions';

const PreoccupationList = () => {
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false); // Track expansion state

    const consciousness = useSelector(state => state.consciousness.consciousness);
    const preoccupations = consciousness ? consciousness.preoccupations : null;
    const otherPreoccupations = useSelector(state => state.consciousness.otherPreoccupations);

    const handleTogglePreoccupation = (uid) => {
        dispatch(togglePreoccupation(uid));
    };

    const handleExpandCollapse = () => {
        if (!isExpanded && !otherPreoccupations) {
            dispatch(getOtherPreoccupations());
        }
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="preoccupation-list-container">
            <div className="preoccupation-title">当下生活重心</div>
            <div className="preoccupations">
                {preoccupations && preoccupations.length > 0 ? (
                    preoccupations.map(preoccupation => (
                        <button 
                            className='preoccupation-button' 
                            key={preoccupation.uid} 
                            onClick={() => handleTogglePreoccupation(preoccupation.uid)}>
                            {preoccupation.name}
                        </button>
                    ))
                ) : (
                    <div className="no-preoccupations">暂无选择</div>
                )}
            </div>
            <div className="expand-button-container">
                <button className="expand-button" onClick={handleExpandCollapse}>
                    {isExpanded ? "收起" : "展开选择"}
                </button>
            </div>
            {isExpanded && otherPreoccupations && (
                <div className="other-preoccupations">
                    {otherPreoccupations.map(preoccupation => (
                        <button 
                            className='preoccupation-button other' 
                            key={preoccupation.uid} 
                            onClick={() => handleTogglePreoccupation(preoccupation.uid)}>
                            {preoccupation.name}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PreoccupationList;