import { GET_PACKAGES, CREATE_ORDER, GET_ORDER } from "../types";

const initialState = {
  packages: [],
  orders: [],
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };
    case CREATE_ORDER:
      return {
        ...state, orders: [...state.orders, action.payload],
      };
    case GET_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) => (order.uid === action.payload.uid ? action.payload : order)),
      };
    default:
      return state;
  }
};
