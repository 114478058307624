import { GET_CALIBRATION_INVENTORY, ADD_CALIBRATION_RECORD } from "../types";

const initialState = {
    inventory: {},
    record: {}
};

export const calibrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CALIBRATION_INVENTORY:
            return { ...state, inventory: action.payload };
        case ADD_CALIBRATION_RECORD:
            return { ...state, record: action.payload };
        default:
            return state;
    }
};