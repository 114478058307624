import React, { useState, useEffect } from 'react';
import './Checkout.css';
import { useDispatch } from 'react-redux';
import { createOrder, getOrder } from '../../redux/actions/orderActions';
import { QRCodeCanvas } from 'qrcode.react';
import { fetchUser } from '../../redux/actions/authActions';
import CloseIcon from '@mui/icons-material/Close';
import ButtonBase from '@mui/material/ButtonBase';

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const Checkout = ({ user, orderType, price, packageUid, confrontationUid, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(isMobileDevice() ? 'alipay_mobile' : null);
  const [payUrl, setPayUrl] = useState(null);
  const [orderUid, setOrderUid] = useState(null);
  
  const handlePayment = async () => {
    setLoading(true);
    setError(null);
    try {
      let orderData;
      if (orderType === 'buy_key') {
        orderData = { keys: 1, token: user.token, payment_method: paymentMethod, confrontation_uid: confrontationUid };
      } else if (orderType === 'buy_package') {
        orderData = { package_uid: packageUid, token: user.token, payment_method: paymentMethod, confrontation_uid: confrontationUid };
      }
      const response = await dispatch(createOrder(orderData));
      setPayUrl(response.pay_url);
      setOrderUid(response.order.uid);
    } catch (error) {
      setError('支付失败。请重试或联系客服。');
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentComplete = async () => {
    setError(null);
    try {
      const order = await dispatch(getOrder(orderUid));
      if (order.order_status === 'completed') {
        dispatch(fetchUser());
        onClose();
      } else {
        setError('支付未完成。请重试或联系客服。');
      }
    } catch (error) {
      setError('无法获取订单状态。请重试或联系客服。');
    }
  };

  useEffect(() => {
    if (payUrl && paymentMethod === 'alipay') {
      const iframe = document.querySelector('.alipay-iframe');
      iframe.src = payUrl;
      iframe.onload = () => setLoading(false);
    } else if (payUrl && paymentMethod === 'alipay_mobile') {
      window.open(payUrl, '_blank');
    }
  }, [payUrl, paymentMethod]);

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-header">
          <CloseIcon className="close-icon" onClick={onClose} />
        </div>
        <div className="popup-title">完成支付</div>
        <div className="popup-content">
          {loading && !isMobileDevice() ? (
            <p>正在加载支付二维码，请稍候...</p>
          ) : (
            <>
              {!payUrl && (
                <>
                  <div className="payment-price"><span>¥</span> {price}</div>
                  <div className="payment-method-title">请选择支付方式：</div>
                  <div className="payment-methods">
                    {!isMobileDevice() ? (
                      <>
                        <ButtonBase
                          className={`payment-method-button ${paymentMethod === 'wechat' ? 'selected' : ''}`}
                          onClick={() => setPaymentMethod('wechat')}
                        >
                          <img src="https://wx.gtimg.com/outwxgtimg/imgs/logo/wxpaylogo_xxxhdpi.png" alt="微信支付" style={{ width: '150px', margin: '10px' }} />
                        </ButtonBase>
                        <ButtonBase
                          className={`payment-method-button ${paymentMethod === 'alipay' ? 'selected' : ''}`}
                          onClick={() => setPaymentMethod('alipay')}
                        >
                          <img src="https://bucket.hansapiens.com/images/alipay.svg" alt="支付宝" style={{ width: '150px', margin: '10px' }} />
                        </ButtonBase>
                      </>
                    ) : (
                      <ButtonBase
                        className={`payment-method-button ${paymentMethod === 'alipay_mobile' ? 'selected' : ''}`}
                        onClick={() => setPaymentMethod('alipay_mobile')}
                      >
                        <img src="https://bucket.hansapiens.com/images/alipay.svg" alt="支付宝" style={{ width: '150px', margin: '10px' }} />
                      </ButtonBase>
                    )}
                  </div>
                  <button className="checkout-button" onClick={handlePayment} disabled={!paymentMethod}>立即支付</button>
                </>
              )}
              {paymentMethod === 'wechat' && payUrl && (
                <div className="payment-qrcode-container wechat">
                  <div className="payment-price"><span>¥</span> {price}</div>
                  <QRCodeCanvas value={payUrl} />
                  <p><img src="https://bucket.hansapiens.com/images/wechatpay_favicon.ico" alt="微信支付Logo"/>请使用微信扫描二维码进行支付：</p>
                  <button className="checkout-button" onClick={handlePaymentComplete}>支付完成</button>
                </div>
              )}
              {paymentMethod === 'alipay' && payUrl && (
                <div className="payment-qrcode-container alipay">
                  <div className="payment-price"><span>¥</span> {price}</div>
                  <iframe
                    className="alipay-iframe"
                    style={{ width: '128px', height: '128px', border: 'none' }}
                  ></iframe>
                  <p><img src="https://bucket.hansapiens.com/images/alipay_favicon.ico" alt="支付宝Logo"/>请使用支付宝扫描二维码进行支付：</p>
                  <button className="checkout-button" onClick={handlePaymentComplete}>支付完成</button>
                </div>
              )}
              {paymentMethod === 'alipay_mobile' && payUrl && (
                <>
                  <p>请在支付宝APP内完成支付</p>
                  <button className="checkout-button" onClick={handlePaymentComplete}>支付完成</button>
                </>
              )}
              {error && <p className="error">{error}</p>}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;