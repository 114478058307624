import { GET_CHOICES, ADD_CHOICE, UPDATE_CHOICE } from '../types';
import { fetchData } from '../../services/api';

export const getChoices = () => async (dispatch) => {
  try {
    const data = await fetchData('choices/');
    dispatch({ type: GET_CHOICES, payload: data });
  } catch (error) {
    console.error('Error getting choices:', error);
  }
};

export const addChoice = (choice, confrontationUid) => async (dispatch) => {
  try {
    const response = await fetchData('choices/', {
      method: 'POST',
      body: JSON.stringify(choice),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch({ type: ADD_CHOICE, payload: response });
  } catch (error) {
    console.error('Error adding choice:', error);
  }
};

export const updateChoice = (choice, confrontationUid) => async (dispatch) => {
  try {
    const response = await fetchData(`choices/${choice.uid}/`, {
      method: 'PATCH',
      body: JSON.stringify(choice),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch({ type: UPDATE_CHOICE, payload: response });
  } catch (error) {
    console.error('Error updating choice:', error);
  }
}
