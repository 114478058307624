import React from 'react';
import ProfileDetail from '../components/Profile/ProfileDetail';

const Profile = () => {
  return (
    <div>
      <main>
        <ProfileDetail />
      </main>
    </div>
  );
};

export default Profile;
