import { GET_PACKAGES, CREATE_ORDER, GET_ORDER } from "../types";
import { fetchData } from '../../services/api';

export const getPackages = () => async (dispatch) => {
    try {
        const data = await fetchData('packages/');
        dispatch({ type: GET_PACKAGES, payload: data });
    } catch (error) {
        console.error('Error getting packages:', error);
    }
}

export const createOrder = (orderData) => async (dispatch) => {
    try {
        const data = await fetchData('create-order/',  { 
            method: 'POST',
            body: JSON.stringify(orderData),
            headers: { 'Content-Type': 'application/json' },
        }); 
        dispatch({ type: CREATE_ORDER, payload: data });
        return data;
    } catch (error) {
        console.error('Error creating order:', error);
    }
};

export const getOrder = (orderUid) => async (dispatch) => {
    try {
        const data = await fetchData(`ord/${orderUid}/`);
        dispatch({ type: GET_ORDER, payload: data });
        return data;
    } catch (error) {
        console.error('Error getting order:', error);
    }
}