import React from 'react';
import RealmList from '../components/Explore/RealmList';
import ArchetypeList from '../components/Explore/ArchetypeList';

const Explore = () => {
  return (
    <div>
      <main>
        <ArchetypeList />
        <RealmList />
      </main>
    </div>
  );
};

export default Explore;
