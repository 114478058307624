import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPackages } from '../../redux/actions/orderActions';
import CloseIcon from '@mui/icons-material/Close';
import Confirm from './Confirm';
import './Continue.css';

const Continue = ({ onClose, onChoice, popupOptions, user }) => {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.orders.packages);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmAction, setConfirmAction] = useState(null);

  useEffect(() => {
    if (!packages || packages.length === 0) {
      dispatch(getPackages());
    }
  }, [dispatch, packages]);

  const handleAbandon = () => {
    setConfirmMessage('您确定要放弃并遗忘梦境吗？');
    setConfirmAction(() => () => {
      setShowConfirm(false);
      onChoice('abandon');
    });
    setShowConfirm(true);
  };

  const handleUseKey = () => {
    setConfirmMessage('您确定要使用 1 把钥匙吗？');
    setConfirmAction(() => () => {
      setShowConfirm(false);
      onChoice('use_key');
    });
    setShowConfirm(true);
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-header">
          <CloseIcon className="close-icon" onClick={onClose} />
        </div>
        <div className="popup-title">确认继续？</div>
        <div className="popup-content">
          {popupOptions.includes('continue') && (
            <div className="card-button card-style-1" onClick={() => onChoice('continue')}>
              <p>确认</p>
            </div>
          )}
          {popupOptions.includes('use_key') && (
            <div className="card-button card-style-1" onClick={handleUseKey}>
              <p>使用 1 把钥匙（剩余 {user.remaining_keys} 把）</p>
            </div>
          )}
          {popupOptions.includes('buy_key') && (
            <div className="card-button card-style-1" onClick={() => onChoice('buy_key', '0.99')}>
              <p>使用 1 把钥匙（剩余 0 把）</p>
              <p>¥ 0.99 / 把</p>
            </div>
          )}
          {popupOptions.includes('buy_package') && (
            <>
              {packages && packages.map((p) => (
                <div key={p.uid} className="card-button card-style-2" onClick={() => onChoice('buy_package', p.price, p.uid)}>
                  <p>升级{p.name}: {p.description}</p>
                  <p>¥ {p.price}</p>
                </div>
              ))}
            </>
          )}
          {popupOptions.includes('abandon') && (
            <div className="card-button card-style-3" onClick={handleAbandon}>
              <p>放弃并遗忘梦境</p>
            </div>
          )}
          {user.has_active_package && (
            user.remaining_usage === 0 ? (
              <div className="exceeded-limit-notice">您有效期内的{user.active_package}已达到使用上限</div>
            ) : (
              <div className="exceeded-limit-notice">您的{user.valid_package}特权已生效</div>
            )
          )}
        </div>
      </div>
      {showConfirm && (
        <Confirm
          onClose={() => setShowConfirm(false)}
          onConfirm={confirmAction}
          message={confirmMessage}
        />
      )}
    </div>
  );
};

export default Continue;