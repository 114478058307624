import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toggleFavoriteFigure } from '../../redux/actions/figureActions';
import './FavoritedFigureList.css'; 

const FavoritedFigureList = () => {
    const dispatch = useDispatch();
    const consciousness = useSelector(state => state.consciousness.consciousness);
    
    const initialFavoritedFigures = useMemo(() => {
        return consciousness ? consciousness.favorited_figures : [];
    }, [consciousness]);

    const [favoritedFigures, setFavoritedFigures] = useState(initialFavoritedFigures);

    useEffect(() => {
        setFavoritedFigures(initialFavoritedFigures);
    }, [initialFavoritedFigures]);

    const handleToggleFavoriteFigure = (figureUid) => {
        dispatch(toggleFavoriteFigure(figureUid)).then(() => {
            const updatedFigures = favoritedFigures.filter(figure => figure.uid !== figureUid);
            setFavoritedFigures(updatedFigures);
        });
    }

    return (
        <div className="favorited-figure-list">
            {favoritedFigures.length > 0 ? (
                favoritedFigures.map(figure => (
                    <div className="figure-item" key={figure.uid}>
                        <Link to={`/f/${figure.uid}`} className="figure-link">
                            {figure.name}
                        </Link>
                        <button className="favorite-toggle-btn" onClick={() => handleToggleFavoriteFigure(figure.uid)}>
                            取消收藏
                        </button>
                    </div>
                ))
            ) : (
                <p className="empty-message">暂未收藏任何人物</p>
            )}
        </div>
    );
}

export default FavoritedFigureList;