import { GET_ARCHETYPES, GET_ARCHETYPE } from "../types";

const initialState = {
    archetypes: [],
};

export const archetypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ARCHETYPES:
            return { ...state, archetypes: action.payload };
        case GET_ARCHETYPE:
            return { ...state, archetypes: state.archetypes.some(archetype => archetype.uid === action.payload.uid)
                ? state.archetypes.map(archetype => archetype.uid === action.payload.uid ? action.payload : archetype)
                : [...state.archetypes, action.payload]
            };
        default:
            return state;
    }
}