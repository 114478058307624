import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getArchetype } from '../../redux/actions/archetypeActions';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './ArchetypeDetail.css'; 
import Loading from '../common/Loading';

const ArchetypeDetail = () => {
    const { uid } = useParams();
    const dispatch = useDispatch();
    const archetype = useSelector(state => state.archetypes.archetypes.find(archetype => archetype.uid === uid));

    useEffect(() => {
        dispatch(getArchetype(uid));
    }, [dispatch, uid]);

    if (!archetype) {
        return <Loading />;
    }

    return (
        <div className="archetype-detail-container">
            <div className="archetype-header">
                <img src={archetype.image} alt={archetype.name} className="archetype-image" />
                <div className="archetype-info">
                    <h2 className="archetype-name">{archetype.name}</h2>
                    <p className="archetype-description">{archetype.description}</p>
                </div>
            </div>
            <h3>相关人物</h3>
            {archetype.figures && archetype.figures.length > 0 ? (
                <div className="archetype-figures">
                    {archetype.figures.map((figure, index) => (
                        <div key={index} className="figure-link">
                            <Link to={`/f/${figure.uid}`}>
                                {figure.name}
                            </Link>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="no-figures">暂无相关人物</div>
            )}
        </div>
    );
}

export default ArchetypeDetail;