import axios from 'axios';

const AUTH_API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/auth/' : 'https://wenjingzhizhi.com/api/auth/';

export const authApi = axios.create({
  baseURL: AUTH_API_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const sendVerificationCode = async (mobile) => {
  try {
    const formData = new URLSearchParams();
    formData.append('mobile', mobile);
    const response = await authApi.post('send-verification-code/', formData);
    return response.data;
  } catch (error) {
    console.error('Error sending verification code:', error);
    throw error;
  }
};

const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/' : 'https://wenjingzhizhi.com/api/';

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const fetchData = async (endpoint, options = {}) => {
  try {
    const response = await api({
      url: endpoint,
      method: options.method || 'GET',
      data: options.body || null,
      headers: options.headers || {},
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};