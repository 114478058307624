import { GET_CONSCIOUSNESS, UPDATE_CONSCIOUSNESS, GET_OTHER_PREOCCUPATIONS, TOGGLE_PREOCCUPATION } from '../types';
import { fetchData } from '../../services/api';

export const getConsciousness = () => async (dispatch) => {
  try {
    const data = await fetchData('current-individual-consciousness/');
    dispatch({ type: GET_CONSCIOUSNESS, payload: data });
  } catch (error) {
    console.error('Error getting consciousness:', error);
  }
};

export const updateConsciousness = (level) => async (dispatch) => {
  try {
    const response = await fetchData('current-individual-consciousness/', {
      method: 'PUT',
      body: JSON.stringify({ level }),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch({ type: UPDATE_CONSCIOUSNESS, payload: response });
  } catch (error) {
    console.error('Error updating consciousness:', error);
  }
};

export const getOtherPreoccupations = () => async (dispatch) => {
  try {
    const data = await fetchData('other-preoccupations/');
    dispatch({ type: GET_OTHER_PREOCCUPATIONS, payload: data });
  } catch (error) {
    console.error('Error getting other preoccupations:', error);
  }
}

export const togglePreoccupation = (uid) => async (dispatch) => {
  try {
    await fetchData(`toggle-preoccupation/${uid}/`, { method: 'POST' });    
    dispatch({ type: TOGGLE_PREOCCUPATION, payload: uid });
  } catch (error) {
    console.error('Error toggling preoccupation:', error);
  }
}