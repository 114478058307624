import React from 'react';
import RealmDetail from '../components/Explore/RealmDetail';

const Realm = () => {
    return (
        <div>
            <main>
            <RealmDetail />
            </main>
        </div>
    );
}

export default Realm;