export const GET_ARCHETYPES = 'GET_ARCHETYPES';
export const GET_ARCHETYPE = 'GET_ARCHETYPE';
export const GET_CALIBRATION_INVENTORY = 'GET_CALIBRATION_INVENTORY';
export const ADD_CALIBRATION_RECORD = 'ADD_CALIBRATION_RECORD';
export const GET_CHOICES = 'GET_CHOICES';
export const ADD_CHOICE = 'ADD_CHOICE';
export const UPDATE_CHOICE = 'UPDATE_CHOICE';
export const GET_CONFRONTATIONS = 'GET_CONFRONTATIONS';
export const GET_ONGOING_CONFRONTATIONS = 'GET_ONGOING_CONFRONTATIONS';
export const ADD_CONFRONTATION = 'ADD_CONFRONTATION';
export const GET_CONFRONTATION = 'GET_CONFRONTATION';
export const ABANDON_CONFRONTATION = 'ABANDON_CONFRONTATION';
export const FORGET_CONFRONTATION = 'FORGET_CONFRONTATION';
export const ADD_UNDERSTANDING = 'ADD_UNDERSTANDING';
export const UPDATE_UNDERSTANDING = 'UPDATE_UNDERSTANDING';
export const SET_IS_ADDING_CHOICE = 'SET_IS_ADDING_CHOICE';
export const GET_CONSCIOUSNESS = 'GET_CONSCIOUSNESS';
export const UPDATE_CONSCIOUSNESS = 'UPDATE_CONSCIOUSNESS';
export const GET_OTHER_PREOCCUPATIONS = 'GET_OTHER_PREOCCUPATIONS';
export const TOGGLE_PREOCCUPATION = 'TOGGLE_PREOCCUPATION';
export const GET_RECOMMENDED_FIGURES = 'GET_RECOMMENDED_FIGURES';
export const GET_CONFRONTED_FIGURES = 'GET_CONFRONTED_FIGURES';
export const GET_FIGURE = 'GET_FIGURE';
export const GET_PACKAGES = 'GET_PACKAGES';
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ORDER = 'GET_ORDER';
export const COMPLETE_PAYMENT = 'COMPLETE_PAYMENT';
export const GET_REALMS = 'GET_REALMS';
export const GET_REALM = 'GET_REALM';
