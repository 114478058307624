import { GET_RECOMMENDED_FIGURES, GET_CONFRONTED_FIGURES, GET_FIGURE } from '../types';

const initialState = {
    recommendedFigures: [],
    confrontedFigures: [],
    figures: [],
};

export const figureReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RECOMMENDED_FIGURES:
            return { ...state, recommendedFigures: action.payload };
        case GET_CONFRONTED_FIGURES:
            return { ...state, confrontedFigures: action.payload };
        case GET_FIGURE:
            const updatedFigures = state.figures.map(figure =>
                figure.id === action.payload.id ? action.payload : figure
            );
            const isFigureExists = state.figures.some(figure => figure.id === action.payload.id);
            return {
                ...state,
                figures: isFigureExists ? updatedFigures : [...state.figures, action.payload]
            };
        default:
            return state;
    }
}