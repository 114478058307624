import { authApi, api } from '../../services/api';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const DECREMENT_KEYS = 'DECREMENT_KEYS';
export const DECREMENT_USAGE = 'DECREMENT_USAGE';

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = (token) => ({ type: LOGIN_SUCCESS, payload: token });
export const loginFailure = (error) => ({ type: LOGIN_FAILURE, payload: error });
export const logoutAction = () => ({ type: LOGOUT });
export const fetchUserRequest = () => ({ type: FETCH_USER_REQUEST });
export const fetchUserSuccess = (user) => ({ type: FETCH_USER_SUCCESS, payload: user });
export const fetchUserFailure = (error) => ({ type: FETCH_USER_FAILURE, payload: error });

export const login = (mobile, code) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const formData = new URLSearchParams();
    formData.append('mobile', mobile);
    formData.append('code', code);
    const response = await authApi.post('/login/', formData);
    const { token } = response.data;
    localStorage.setItem('token', token);
    dispatch(loginSuccess(token));
    dispatch(fetchUser());
    return true;
  } catch (error) {
    console.error('Error verifying code:', error);
    dispatch(loginFailure(error.message));
    return false;
  }
};

export const fetchUser = () => async (dispatch) => {
  dispatch(fetchUserRequest());
  try {
    const response = await api.get('/user/');
    dispatch(fetchUserSuccess(response.data));
  } catch (error) {
    console.error('Error fetching user:', error);
    dispatch(fetchUserFailure(error.message));
  }
};

export const logout = () => async (dispatch) => {
  try {
    await api.post('/logout/');
  } catch (error) {
    console.error('Error logging out:', error);
  }
  localStorage.removeItem('token');
  dispatch(logoutAction());
};

export const decrementKeys = () => ({ type: DECREMENT_KEYS });
export const decrementUsage = () => ({ type: DECREMENT_USAGE });