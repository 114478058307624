import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getArchetypes } from '../../redux/actions/archetypeActions';
import './ArchetypeList.css';
import Loading from '../common/Loading';

const ArchetypeList = () => {
    const dispatch = useDispatch();
    const archetypes = useSelector(state => state.archetypes.archetypes);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        if (!archetypes || archetypes.length <= 1) {
            dispatch(getArchetypes()).then(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [dispatch, archetypes]);
    
    if (loading) {
        return <Loading />;
    }
    
    return (
        <div className="archetype-list">
            <h3 className="archetype-list-heading">荣格原型</h3>
            <div className="archetype-items">
                {archetypes.map(archetype => (
                    <Link to={`/a/${archetype.uid}`}>
                        <div key={archetype.uid} className="archetype-item">
                            {archetype.name}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ArchetypeList;