import React, { useState, useEffect } from 'react';
import './Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/authActions';
import { selectAuthError, selectIsLoading } from '../../redux/selectors';
import { Navigate } from 'react-router-dom';
import { sendVerificationCode } from '../../services/api'; 
import logo from '../../logo.svg';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');
  const [isCodeButtonDisabled, setIsCodeButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const error = useSelector(selectAuthError);
  const isLoading = useSelector(selectIsLoading);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setIsCodeButtonDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const success = await dispatch(login(phoneNumber, verificationCode));
    if (!success) {
      setMessage('Login failed. Please check your credentials and try again.');
    }
  };

  const requestVerificationCode = async () => {
    try {
      await sendVerificationCode(phoneNumber);
      setMessage('Verification code sent successfully');
      setIsCodeButtonDisabled(true);
      setCountdown(60);
    } catch (error) {
      setMessage('Error sending verification code');
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div className="login-container-wrapper">
      <div className="login-content">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="login-logo" />
        </div>
        <form onSubmit={handleLogin} className="login-container">
          <div className="login-title">手机号登录</div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {message && <p style={{ color: message.includes('Error') ? 'red' : 'green' }}>{message}</p>}
          <div className="phone-input">
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="请输入手机号"
              required
            />
          </div>
          <div className="code-input">
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="请输入验证码"
              required
            />
            <button
              type="button"
              className="get-code-button"
              onClick={requestVerificationCode}
              disabled={phoneNumber.length !== 11 || isCodeButtonDisabled}
            >
              {isCodeButtonDisabled ? `重新获取(${countdown})` : '获取验证码'}
            </button>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="user-consent"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              required
            />
            <label htmlFor="user-consent">
              勾选即代表您阅读并同意
              <a href="https://wenjingzhizhi.com/terms" target="_blank" rel="noopener noreferrer">《服务条款》</a>
              和
              <a href="https://wenjingzhizhi.com/privacy" target="_blank" rel="noopener noreferrer">《隐私政策》</a>
              <br />
              未注册手机号将自动注册
            </label>
          </div>
          <button className='login-button' type="submit" disabled={isLoading || !isChecked}>
            {isLoading ? '登录中...' : '登录'}
          </button>
        </form>
        <div className="footer-text">文景知智 © {new Date().getFullYear()}</div>
      </div>
    </div>
  );
};

export default Login;